export const cdcScreenSets = {
  REGISTRATION: {
    SCREEN_SET: 'MB-RegistrationLogin',
    BASIC_REGISTRATION_SCREEN: 'mb-gigya-register-screen',
    COMPLETE_REGISTRATION_SCREEN: 'gigya-complete-registration-screen',
    EMAIL_CODE_VERIFY_SCREEN: 'gigya-email-code-verification-screen',
    CONTAINER_ID: 'content'
  },
  LOGIN: {
    SCREEN_SET: 'MB-RegistrationLogin',
    LOGIN_WITH_REDIRECT_SCREEN: 'mb-gigya-login-screen-with-redirect',
    RESET_PASSWORD_SCREEN: 'gigya-reset-password-screen',
    CONTAINER_ID: 'content'
  },
  PROFILE: {
    SCREEN_SET: 'MB-ProfileUpdate',
    UPDATE_PROFILE_SCREEN: 'gigya-update-profile-screen',
    CHANGE_PASSWORD_SCREEN: 'gigya-change-password-screen',
    CONTAINER_ID: 'profile'
  }
}