import {getURLParam, includeScript} from './helper'
import {cdcScreenSets} from "./constants";

window.onload = () => {
  setTimeout(() => {
    ensureRedirectFlow()
  }, 1500)
}

function ensureRedirectFlow() {
  let params = new URLSearchParams(window.location.search)
  let clientID = JSON.parse(sessionStorage.getItem("gigya._authnContext"))?.client
  let protocol = JSON.parse(sessionStorage.getItem("gigya._authnContext"))?.protocol
  if ((!clientID && !protocol && params.get('oidc') == '1') || window.location.search === '') {
    redirectToAuth()
  }
}

function redirectToAuth() {
  window.location.href = `${process.env.OAUTHURL}?client_id=${process.env.CLIENTID}&redirect_uri=${process.env.MYACCOUNTSITE}`
}

document.addEventListener("DOMContentLoaded", () => {
  includeScript(`https://cdns.gigya.com/js/gigya.js?apiKey=${process.env.APIKEY}`)
});


onGigyaServiceReady = (function (existingGSR) {
  return (function () {

    // check for gigya dependencies
    !function (a, b) {
      a = "[" + a + "]",
      b && void 0 === window.gigya && console.error(a + " The gigya object does not exist."),
        console.log(a + " Loading..."),
      "function" == typeof existingGSR && (console.log(a + "... Executing prior onGigyaServiceReady functions"),
        existingGSR(),
        console.log(a + "... Now continuing with this onGigyaServiceReady"))
    }("login.js", 0)

    // true = change pages in registration workflow; false = use screensets in page
    gigya._myContext.authn.context.set({useRedirects: false});
    // for convenience...
    _client = gigya._myContext.authn.context.get()['client'];

    document.addEventListener("gigyaEvent", function (e) {
      switch (e.detail.event) {
        case 'changeScreen':
        default:
          console.log("Unhandled", e.type, e);
      }
      adjustScreen(e.detail.triggeringEvent.currentScreen)
    });
    gigya.accounts.showScreenSet(getScreenSetParams());
  });
})((typeof onGigyaServiceReady == 'undefined') ? null : onGigyaServiceReady);

function adjustScreen(screenName) {
  switch (screenName) {
    case cdcScreenSets.LOGIN.LOGIN_WITH_REDIRECT_SCREEN:
      removeRegistrationClasses();
      break;
    case cdcScreenSets.REGISTRATION.BASIC_REGISTRATION_SCREEN:
      addRegistrationClasses();
      break;
    case cdcScreenSets.REGISTRATION.COMPLETE_REGISTRATION_SCREEN:
      addRegistrationClasses();
      break;
  }
}

function addRegistrationClasses() {
  let elementClassList = [
    'login-tile',
    'welcome-tile',
    'login',
    'gigya-screen-caption'
  ]

  elementClassList.forEach((e) => {
    document.getElementsByClassName(e)[0]?.classList.add(e + '-registration');
  });
}

function removeRegistrationClasses() {
  let elementClassList = [
    'login-tile',
    'welcome-tile',
    'login',
    'gigya-screen-caption'
  ]

  elementClassList.forEach((e) => {
    document.getElementsByClassName(e)[0]?.classList.remove(e + '-registration');
  });
}

function getScreenSetParams() {
  let startScreen, screenSet, containerId;
  switch (getURLParam('screen')) {
    case 'verify':
      screenSet = cdcScreenSets.REGISTRATION.SCREEN_SET
      startScreen = cdcScreenSets.REGISTRATION.EMAIL_CODE_VERIFY_SCREEN
      containerId = cdcScreenSets.REGISTRATION.CONTAINER_ID
      break
    case 'passwordReset':
      screenSet = cdcScreenSets.LOGIN.SCREEN_SET
      startScreen = cdcScreenSets.LOGIN.RESET_PASSWORD_SCREEN
      containerId = cdcScreenSets.LOGIN.CONTAINER_ID
      break
    case 'passwordChange':
      screenSet = cdcScreenSets.PROFILE.SCREEN_SET
      startScreen = cdcScreenSets.PROFILE.CHANGE_PASSWORD_SCREEN
      containerId = cdcScreenSets.PROFILE.CONTAINER_ID
      break
    default:
      screenSet = cdcScreenSets.LOGIN.SCREEN_SET
      startScreen = cdcScreenSets.LOGIN.LOGIN_WITH_REDIRECT_SCREEN
      containerId = cdcScreenSets.LOGIN.CONTAINER_ID
      break
  }

  return {
    containerID: containerId,
    screenSet: screenSet,
    startScreen: startScreen,
    context: {
      refreshContext: getURLParam('refreshContext') || false
    },
    onAfterScreenLoad: function (e) {
      document.dispatchEvent(new CustomEvent("gigyaEvent", {
        detail: {
          event: "changeScreen",
          reason: "Showing screen for " + _client,
          triggeringEvent: e
        }
      }));
    },
    onAfterSubmit: function (event) {
      return event;
    }
  }
}